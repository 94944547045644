/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var MAIN = {};

  MAIN.hideUnusedNav = function () {
    $('.bogo-language-switcher li').each(function() {
      if ( !$('a', this)[0] && !$(this).hasClass('current') ) {
        $(this).hide();
      }
    });
  };

  MAIN.setMenu = function () {
    var m = $('#icon-menu'),
        n = $('.banner'),
        w = $(window),
        b = $('body'),
        c = $('.menu-contact > a'),
        f = $('.menu-contact .sub-menu');

    $('.menu-contact').removeClass('active');

    m.on('click', function () {
      $(this).toggleClass('is-open');
      n.toggleClass('is-open');
      if (f.hasClass('is-open')) {
        f.removeClass('is-open');
      }
      return false;
    });

    c.on('click', function () {
      f.toggleClass('is-open');
      return false;
    });
  };

  MAIN.setSwiper = function() {
    const swiper = new Swiper('.swiper-container', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true
    });
  };

  MAIN.setIas = function () {
    var ias = $.ias({
      container: '.projects-body',
      item: '.projects-item',
      pagination: '.pagination',
      next: '.nav-links .next'
    });

    ias.extension(new IASSpinnerExtension({
      src: '/wp-content/themes/kontakt/dist/images/loading.svg'
    }));

    ias.extension(new IASTriggerExtension({
      text: 'More'
    }));
  };

  MAIN.setWaypoints = function() {
    const waypoints = $('.projects-single-nav > a').waypoint({
      handler: function(d) {
        if (d === 'down') {
          $(this.element).addClass('is-sticky');
        } else {
          $(this.element).removeClass('is-sticky');
        }
      },
      offset: 132
    });
  };

  MAIN.setToggleTaxonomy = function () {
    const b = $('#toggle-taxonomy');
    b.on('click', function(){
      $('#toggle-taxonomy, #menu-taxonomy').toggleClass('is-open');
      return false;
    });
  };

  MAIN.setToggleClients = function () {
    const b = $('#toggle-all-clients');
    b.on('click', function(){
      $('#toggle-all-clients, .client__all').toggleClass('is-open');
      return false;
    });
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        MAIN.hideUnusedNav();
        MAIN.setMenu();
      },
      finalize: function() {}
    },
    // Home page
    'home': {
      init: function() {
        MAIN.setIas();
      },
      finalize: function() {}
    },
    'single_projects': {
      init: function() {
        MAIN.setSwiper();
        MAIN.setWaypoints();
      }
    },
    'post_type_archive': {
      init: function () {
        MAIN.setIas();
        MAIN.setToggleTaxonomy();
      }
    },
    'page_template_template_overview': {
      init: function () {
        MAIN.setToggleClients();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
